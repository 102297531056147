// filepath: /c:/Users/polus/Desktop/cheat-project/site-delusion/src/App.js
import React from "react";
import Signup from "./signup/Signup";
import Login from "./login/Login";
import Layout from "./layout/Layout"; // Import the Layout component
import Dashboard from "./dashboard/Dashboard";
import Account from "./dashboard/account/DashboardAccount";
import Billing from "./dashboard/billing/Billing";
import { AuthProvider } from "./auth/AuthContext"; // Import AuthProvider
import "./App.css";
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_live_51QQ7P0GXWusEZVHG7mYCzyCjncglNqDi6fa1PzKhM5FhAbUMjNEB98RpLRSbL2yFpHUjSe046bbWVLNuUBAEEq2w00kzmFUeC7");

function App() {
  const route = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Signup />
        </Layout>
      ),
    },
    {
      path: "/login",
      element: (
        <Layout>
          <Login />
        </Layout>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AuthProvider> {/* Wrap the dashboard routes with AuthProvider */}
          <Dashboard />
        </AuthProvider>
      ),
      children: [
        {
          path: "account",  // Updated path to match the 'Link' in the sidebar
          element: <Account />,
        },
        {
          path: "billing",
          element: (
            <Elements stripe={stripePromise}>
              <Billing />
            </Elements>
          ),
        },
        // Define other child routes such as 'support', 'billing', etc.
      ],
    },
    {
      path: "/static/*",
      element: <Navigate to="/" replace />, // Redirect to Signup page
    },
    {
      path: "/static/js/*",
      element: <Navigate to="/" replace />, // Redirect to Signup page
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={route} />
    </div>
  );
}

export default App;