import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { FaUser, FaCreditCard, FaDownload, FaSignOutAlt } from "react-icons/fa";
import "./dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Access the current location object
  const [subscriptionStatus, setSubscriptionStatus] = useState("Loading...");
  const [username, setUsername] = useState("");
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedCredits = localStorage.getItem('credits');
    if (storedUsername) {
      setUsername(storedUsername);
    }
    if (storedCredits) {
      setCredits(storedCredits);
    }
  }, []);

  const logOut = async () => {
    try {
      const response = await fetch("https://delusion-backend-38a1c2e67e35.herokuapp.com/logout/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies for authentication
      });

      if (response.ok) {
        // Redirect to the login page after a successful logout
        navigate("/login");
      } else {
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  useEffect(() => {
    // Fetch the subscription status every time the location changes
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await fetch(
          "https://delusion-backend-38a1c2e67e35.herokuapp.com/sub_expire_date",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch subscription status");
        }

        const data = await response.json();
        setSubscriptionStatus(data.status);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setSubscriptionStatus("Error fetching status");
      }
    };

    fetchSubscriptionStatus();
  }, [location]); // Trigger effect when location changes

  const handleDownload = () => {
    const fileUrl = "/loader.7z"; // Path to the file
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Convert response to a Blob object
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "loader.7z"); // Set the filename
        document.body.appendChild(link);
        link.click(); // Trigger download
        link.remove(); // Clean up the link
      })
      .catch((error) => console.error("Error downloading file:", error));
  };
  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <aside className="dashboard-sidebar">
        <div className="dashboard-logo">
          <img src="/delusion_logo.png" alt="Delusion Logo" />
        </div>
        <div className="user-container">
          <NavLink to="account" className="user-info">
            <div className="user-info-content">
              <div className="user-icon">
                <FaUser />
              </div>
              <div className="username-display">{username}</div>
            </div>
          </NavLink>
          <div className="credits-container">
            Credits: ${credits}
          </div>
        </div>
        <nav className="dashboard-nav">
          <ul>
            <li>
              <NavLink 
                to="account" 
                className={({ isActive }) => `dashboard-link${isActive ? ' active' : ''}`}
              >
                <FaUser className="icon" /> Personal
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="billing" 
                className={({ isActive }) => `dashboard-link${isActive ? ' active' : ''}`}
              >
                <FaCreditCard className="icon" /> Billing
              </NavLink>
            </li>
            
            {/* Only show download link if subscription is not inactive */}
            {subscriptionStatus !== "Inactive" && (
              <li>
                <NavLink
                  to="#"
                  className="download-link"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload();
                  }}
                >
                  <FaDownload className="icon" /> Download
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
  
        {/* Logout Link */}
        <ul className="logout-container">
          <li>
            <NavLink
              to="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent navigation
                logOut(); // Trigger the log out function
              }}
              className="logout-link"
            >
              <FaSignOutAlt className="icon" /> Log Out
            </NavLink>
          </li>
        </ul>
      </aside>
  
      {/* Main Content Area */}
      <main className="dashboard-content">
        <Outlet /> {/* Render child components dynamically */}
      </main>
    </div>
  );
};

export default Dashboard;
