import React, { useState, useEffect } from "react";
import "./DashboardAccount.css";
import { useNavigate } from "react-router-dom";
import sha256 from 'js-sha256';

const Account = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    hash_password: "",
    refferal: ""
  });

  const [isEditing, setIsEditing] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [activeField, setActiveField] = useState(null);
  const [popupFormData, setPopupFormData] = useState({});

  const hash_var = (input) => sha256(input);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch("https://delusion-backend-38a1c2e67e35.herokuapp.com/info/user/", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include", // Include cookies
        });

        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

        const jsonResponse = await response.json();

        if (jsonResponse === "Unauthorized") {
          navigate("/login");
          return;
        }

        // Store username and credits in localStorage
        localStorage.setItem('username', jsonResponse.username);
        localStorage.setItem('credits', jsonResponse.refferal_credits);

        setFormData({
          username: jsonResponse.username,
          email: jsonResponse.email,
          password: "maskedpass",
          hash_password: jsonResponse.password,
          refferal: jsonResponse.refferal
        });
      } catch (error) {
        console.error("Error fetching user info:", error);
        setResponseMessage("Error fetching account details.");
      }
    };

    fetchUserInfo();
  }, [navigate]);

  const handleEditToggle = (field) => {
    setActiveField(field);
    setIsEditing(true);
    setResponseMessage(""); // Clear messages on toggle
    setPopupFormData({ [field]: formData[field] }); // Initialize popup form data
  };

  const handlePopupChange = (e) => {
    setPopupFormData({
      ...popupFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (activeField === "password") {
      if (popupFormData.newPassword !== popupFormData.confirmPassword) {
        setResponseMessage("New Password and Confirm New Password must match.");
        return;
      }

      if (formData.hash_password !== await hash_var(popupFormData.oldPassword)) {
        setResponseMessage("Your old password is incorrect");
        return;
      }
    }

    try {
      let json_body = "";

      if (activeField === "password") {
        json_body = JSON.stringify({
          password: await hash_var(popupFormData.newPassword),
          email: formData.email
        });
      } else if (activeField === "email") {
        json_body = JSON.stringify({
          password: formData.hash_password,
          email: popupFormData.email
        });
      }

      const response = await fetch("https://delusion-backend-38a1c2e67e35.herokuapp.com/info/user/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies
        body: json_body
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const jsonResponse = await response.json();

      if (jsonResponse === "Unauthorized") {
        navigate("/login");
        return;
      }

      setFormData({
        username: jsonResponse.name,
        email: jsonResponse.email,
        password: "maskedpass",
        hash_password: jsonResponse.password, // Assume the backend is sending a hashed or masked password
        refferal : jsonResponse.refferal
      });

      setResponseMessage("Account details updated successfully!");

      setTimeout(() => {
        setIsEditing(false);
        setActiveField(null);
      }, 2000);
    } catch (error) {
      console.error("Error updating user info:", error);
      setResponseMessage("Error updating account details.");
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setActiveField(null);
  };

  const closePopup = () => {
    setIsEditing(false);
    setActiveField(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.className === "popup") {
      closePopup();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      closePopup();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="dashboard-account">

      <div className="account-input">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          value={formData.username}
          readOnly
        />
      </div>

      <div className="account-input">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          readOnly
        />
      </div>

      <div className="account-input">
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          readOnly
        />
        <button onClick={() => handleEditToggle("password")}>
          Change
        </button>
      </div>

      <div className="account-input">
        <label htmlFor="refferal">Refferal code:</label>
        <input
          type="text"
          id="refferal"
          name="refferal"
          value={formData.refferal}
          readOnly
        />
      </div>

      {isEditing && activeField && (
        <div className="popup" onClick={handleOutsideClick}>
          <div className="popup-content">
            <span className="popup-close" onClick={closePopup}>
              X
            </span>
            <h3>{activeField === "password" ? "Change Password" : "Change Email"}</h3>
            <form onSubmit={handleSubmit}>
              {activeField === "password" && (
                <>
                  <div className="account-input">
                    <label htmlFor="oldPassword">Old Password:</label>
                    <input
                      type="password"
                      id="oldPassword"
                      name="oldPassword"
                      value={popupFormData.oldPassword || ""}
                      onChange={handlePopupChange}
                      required
                    />
                  </div>
                  <div className="account-input">
                    <label htmlFor="newPassword">New Password:</label>
                    <input
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      value={popupFormData.newPassword || ""}
                      onChange={handlePopupChange}
                      required
                    />
                  </div>
                  <div className="account-input">
                    <label htmlFor="confirmPassword">Confirm New Password:</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={popupFormData.confirmPassword || ""}
                      onChange={handlePopupChange}
                      required
                    />
                  </div>
                </>
              )}

              {activeField === "email" && (
                <div className="account-input">
                  <label htmlFor="email">New Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={popupFormData.email || ""}
                    onChange={handlePopupChange}
                    required
                  />
                </div>
              )}

              <div className="button-group">
                <button type="submit" className="btn-save">
                  Save
                </button>
                <button type="button" className="btn-cancel" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </form>
            {responseMessage && (
              <div
                className={`response-message ${responseMessage === "Account details updated successfully!" ? "success" : "error"}`}
              >
                {responseMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
